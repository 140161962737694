/**
 * @file Root Context wrapper that houses global states (auth & menu)
 * @author Alwyn Tan
 */

import React from 'react'

// set the defaults
const RootContext = React.createContext({
  user: {},
  setUser: () => {},
  showAuth: false,
  setShowAuth: () => {},
  onAuthSuccess: null, // will be a ref instance
  jsConfetti: null, // confetti instance
})

export default RootContext
