/**
 * @file Analytics helpers
 * @author Alwyn Tan
 */

export const makeDropProperties = drop => ({
  'Drop ID': drop?.id,
  Description: drop?.description,
  Address: drop?.address,
  Location: drop?.location,
  Categories: drop?.categories,
  Link: drop?.link,
  Response: drop?.response,
  'Created At': drop?.createdAt,
  'Updated At': drop?.updatedAt,
})

export const makeLinkProperties = link => ({
  'Link ID': link?.id,
  'Short ID': link?.shortID,
  'Intended For ID:': link?.intendedFor?.id,
  'Created At': link?.createdAt,
  'Updated At': link?.updatedAt,
  Type: link?.type,
  Data: link?.data,
})
