/**
 * @file Request Login Hook
 * @author Alwyn Tan
 */

import { useMutation } from 'react-query'
import api from 'utils/api'

const postRequestLogin = async phoneNumber =>
  api
    .post('user/request-login', {
      json: { phoneNumber },
      credentials: 'include',
    })
    .json()

const useRequestLogin = () =>
  useMutation(async phoneNumber => postRequestLogin(phoneNumber))

export default useRequestLogin
