/**
 * @file Silent Login Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import RootContext from 'RootContext'
import api from 'utils/api'

const postSilentLogin = async () =>
  api
    .post('user/silent-login', {
      credentials: 'include',
      throwHttpErrors: false,
    })
    .json()

const useSilentLogin = () => {
  const { setUser } = useContext(RootContext)

  return useMutation(async () => {
    const { accessToken, user } = await postSilentLogin()
    if (accessToken) {
      delete user.fcmTokens
      window.analytics.identify(user.id, { id: user.id })
      setUser({ ...user, accessToken })
    } else {
      setUser({})
    }
    return { user }
  })
}

export default useSilentLogin
