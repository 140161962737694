/**
 * @file Update User Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import { serialize } from 'object-to-formdata'
import RootContext from 'RootContext'
import api from 'utils/api'

const putUpdateUser = async (id, details) =>
  api.put(`user/${id}`, { body: serialize(details) }).json()
// {
//   const body = serialize(details)

//   return fetch(`${UPDATE_USER_URL}/${id}`, {
//     method: 'PUT',
//     body,
//     headers: { Authorization: `Bearer ${accessToken}` },
//   }).then(response => response.json())
// }

const useUpdateUser = () => {
  const { user, setUser } = useContext(RootContext)

  return useMutation(async details => {
    const response = await putUpdateUser(user.id, details)
    setUser({ ...user, ...response.user })
    return { success: response.user }
  })
}

export default useUpdateUser
