/**
 * @file Link Page that handles rerouting, etc
 * @author Alwyn Tan
 */

import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useGetLink from 'hooks/query/link/useGetLink'
import { makeLinkProperties } from 'utils/analytics'

const LinkPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const { data, isLoading } = useGetLink(id)
  const link = data?.link

  useEffect(() => {
    if (!isLoading) {
      if (!link) {
        history.push('/404')
      } else if (link && link.type === 'drop') {
        window.analytics.track('Link Viewed', makeLinkProperties(link))
        history.push(`/drop/${link.data.dropID}`, {
          link,
        })
      }
    }
  }, [link, isLoading, history])

  return null
}

export default LinkPage
