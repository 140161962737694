/**
 * @file save drop response
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import api from 'utils/api'

const saveDropResponse = async (dropID, linkID, response) =>
  api.put(`drop/response/${dropID}`, { json: { linkID, response } }).json()

const useSaveDropResponse = ({ dropID, linkID }) => {
  const queryClient = useQueryClient()
  return useMutation(async response => {
    const r = await saveDropResponse(dropID, linkID, response)
    queryClient.invalidateQueries(['drop', dropID])
    return r
  })
}

export default useSaveDropResponse
