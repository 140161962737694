import React, { useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import JSConfetti from 'js-confetti'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from 'styled-components'
import { Toaster } from 'react-hot-toast'
import RootContext from 'RootContext'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient()
const helmetContext = {}
const jsConfetti = new JSConfetti()
const theme = {
  Error: '#FC4949',
}

const Root = () => {
  const [user, setUser] = useState(null)
  const [showAuth, setShowAuth] = useState(false)
  const onAuthSuccess = useRef(null)

  return (
    <React.StrictMode>
      <HelmetProvider context={helmetContext}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <RootContext.Provider
              value={{
                user,
                setUser,
                showAuth,
                setShowAuth,
                onAuthSuccess,
                jsConfetti,
              }}
            >
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </RootContext.Provider>
          </BrowserRouter>
        </QueryClientProvider>
      </HelmetProvider>
      <Toaster
        reverseOrder
        toastOptions={{
          style: {
            borderRadius: 200,
            backgroundColor: 'white',
            color: theme.Text,
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 14,
          },
        }}
      />
    </React.StrictMode>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
