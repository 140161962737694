/**
 * @file Hook to save a user viewing a drop (sends a notif to host)
 * @author Alwyn Tan
 */

import { useMutation } from 'react-query'
import api from 'utils/api'

const saveDropView = async (dropID, linkID) =>
  api.put(`drop/view/${dropID}`, { json: { linkID } }).json()

const useSaveDropView = ({ dropID, linkID }) =>
  useMutation(async () => saveDropView(dropID, linkID))

export default useSaveDropView
