/**
 * @file Get Drop
 * @author Alwyn Tan
 */

import { useQuery } from 'react-query'
import api from 'utils/api'

const getDrop = async id => api.get(`drop/${id}`).json()

// NOTE: there is a problem here, when user updates, the useQuery instance doesn't actually update!
// todo: fix this using a library like ky that sets headers directly on a global request obj, current hack : refresh page
const useGetDrop = id =>
  useQuery(['drop', id], async () => getDrop(id), {
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  })

export default useGetDrop
