/**
 * @file Auth Overlay Page (controls all the auth elements in 1 page)
 * @author Alwyn Tan
 */

import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import LoginCard from 'components/compound/Auth/LoginCard'
import RequestLoginCard from 'components/compound/Auth/RequestLoginCard'
import NameCard from 'components/compound/Auth/NameCard'
import RootContext from 'RootContext'

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`

const CardWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  bottom: 0;
`

const Auth = () => {
  const { user, showAuth, onAuthSuccess } = useContext(RootContext)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [authIndex, setAuthIndex] = useState(0)
  const [prevIndex, setPrevIndex] = useState(0)

  useEffect(() => {
    if (user?.accessToken) {
      if (user?.name) {
        setPrevIndex(authIndex)
        setAuthIndex(-1)
      } else {
        setPrevIndex(authIndex)
        setAuthIndex(2)
      }
    }
  }, [user?.accessToken, user?.name, authIndex])

  useEffect(() => {
    if (user?.accessToken && onAuthSuccess.current) {
      onAuthSuccess.current()
      onAuthSuccess.current = null
    }
  }, [user?.accessToken, onAuthSuccess])

  const handleLoginRequested = async number => {
    setPhoneNumber(number)
    setPrevIndex(authIndex)
    setAuthIndex(1)
  }

  const handleLoginBack = () => {
    setPrevIndex(authIndex)
    setAuthIndex(0)
  }

  return (
    <AnimatePresence>
      {showAuth && authIndex >= 0 && (
        <Container
          initial={{ backgroundColor: '#00000000' }}
          animate={{ backgroundColor: '#000000E6' }}
          exit={{ backgroundColor: '#00000000' }}
          transition={{ ease: 'easeInOut', duration: 0.1 }}
        >
          <CardWrapper
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 1000 }}
          >
            <AnimatePresence>
              {authIndex === 0 && (
                <RequestLoginCard
                  key="0"
                  phoneNumber={phoneNumber}
                  performBackAnimation={prevIndex > 0}
                  onLoginRequested={handleLoginRequested}
                />
              )}
              {authIndex === 1 && (
                <LoginCard
                  key="1"
                  onBack={handleLoginBack}
                  phoneNumber={phoneNumber}
                />
              )}
              {authIndex === 2 && <NameCard />}
            </AnimatePresence>
          </CardWrapper>
        </Container>
      )}
    </AnimatePresence>
  )
}

export default Auth
