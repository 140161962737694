/**
 * @file Request a login (phone input card)
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import Input from 'components/simple/Input'
import { motion } from 'framer-motion'
import chevronLeft from 'images/chevron-left.png'
import useLogin from 'hooks/query/user/useLogin'

const Container = styled(motion.div)`
  padding: 15px 15px 20px 15px;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
`

const InnerContainer = styled.div`
  padding: 20px 20px 25px 20px;
  background: #b0d104;
  border-radius: 19px;
  width: 100%;
  text-align: center;
  max-width: 410px;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ChevronLeft = styled.div`
  background-image: url(${({ src }) => src});
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: 45% 50%;
  padding: 12px;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #bde329;
  cursor: pointer;
`

const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  color: white;
`

const Subtitle = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: white;
  padding-top: 5px;
  padding-bottom: 20px;
`

const ResendButton = styled.button`
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
  margin-top: 25px;
  cursor: pointer;
`

const LoginCard = ({ onBack, phoneNumber }) => {
  const { control, setError } = useForm({ mode: 'onChange' })
  const [doBackAnim, setDoBackAnim] = useState()

  const loginMutation = useLogin()

  const handleBackClick = () => {
    setDoBackAnim(true)
    onBack()
  }

  const handleInputChange = async val => {
    if (val.length === 4) {
      try {
        await loginMutation.mutateAsync({ phoneNumber, code: val })
      } catch (e) {
        setError('code', { message: e.message })
      }
    }
  }

  return (
    <Container
      initial={{ x: 1000 }}
      animate={{ x: 0 }}
      exit={{ x: doBackAnim ? 1000 : -1000 }}
    >
      <InnerContainer>
        <TitleRow>
          <ChevronLeft src={chevronLeft} onClick={handleBackClick} />
          <Title>Verify that it’s you</Title>
          <div style={{ width: 32, opacity: 0 }} />
        </TitleRow>
        <Subtitle>Code sent to {phoneNumber}</Subtitle>
        <Input
          onChange={handleInputChange}
          maxLength={4}
          control={control}
          name="code"
          placeholder="Enter Code"
          style={{ backgroundColor: '#BDE329' }}
        />
        <ResendButton>Resend Code</ResendButton>
      </InnerContainer>
    </Container>
  )
}

LoginCard.propTypes = {
  onBack: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
}

export default LoginCard
