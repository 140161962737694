/**
 * @file Get link query
 * @author Alwyn Tan
 */

import { useQuery } from 'react-query'
import api from 'utils/api'

const getLink = async id => api.get(`link/${id}`).json()

const useGetLink = id =>
  useQuery(['link', id], async () => getLink(id), {
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  })

export default useGetLink
