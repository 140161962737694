/**
 * @file Login Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import RootContext from 'RootContext'
import api from 'utils/api'

const postLogin = async ({ phoneNumber, code }) =>
  api
    .post('user/login', { json: { phoneNumber, code }, credentials: 'include' })
    .json()

const useLogin = () => {
  const { setUser } = useContext(RootContext)

  return useMutation(async ({ phoneNumber, code }) => {
    const { accessToken, user } = await postLogin({ phoneNumber, code })
    if (accessToken) {
      delete user.fcmTokens
      window.analytics.identify(user.id, { id: user.id })
      setUser({ ...user, accessToken })
    } else {
      setUser({})
    }
    return { user }
  })
}

export default useLogin
