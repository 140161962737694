/**
 * @file Drops to share
 * @author Alwyn Tan
 */

import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import RootContext from 'RootContext'
import pin from 'images/pin.png'
import spinner from 'images/spinner.png'
import useGetDrop from 'hooks/query/drop/useGetDrop'
import useSaveDropResponse from 'hooks/query/drop/useSaveDropResponse'
import useSaveDropView from 'hooks/query/drop/useSaveDropView'
import SEO from 'components/simple/SEO'
import { makeDropProperties } from 'utils/analytics'

const Container = styled.div`
  background-color: #131313;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const MaxMobileSizeContainer = styled.div`
  max-height: 896px;
  max-width: 414px;
  height: 100%;
  width: 100%;
  background-color: #0c0c0c;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: white;
`

const Title = styled.h5`
  padding-top: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  text-align: center;
`

const BottomContainer = styled.div`
  margin-top: auto;
  padding: 0 16px;
`

const Descriptions = styled(motion.div)`
  padding: 32px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Location = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  padding: 6px;
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  margin-top: 3px;
  cursor: pointer;
  max-width: 100%;
  align-items: center;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Pin = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px;
  height: 12.5px;
  min-width: 10px;
  margin-right: 6px;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const BulletContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: white;
  white-space: pre-line;
`

const GradientOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.29) 0%,
    rgba(0, 0, 0, 0.1) 44.79%,
    rgba(0, 0, 0, 0.161321) 55.73%,
    rgba(0, 0, 0, 0.6) 100%
  );
  border-radius: inherit;
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  pointer-events: none;
  position: absolute;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
`

const Response = styled.div`
  display: flex;
  margin-bottom: 24px;
`

const Button = styled.button`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: white;
  border-radius: 50px;
  outline: none;
  border: 0;
  cursor: pointer;
  background: ${({ response, value }) =>
    !response || response === value ? '#9E24BF' : 'rgba(255, 255, 255, 0.15)'};
  backdrop-filter: blur(115px);
  user-select: none;
  opacity: ${({ response, value }) =>
    !response || response === value ? 1 : 0.5};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 4px;
  flex: 1;
`

const Spinner = styled(motion.div)`
  height: 25px;
  width: 25px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: calc(50% - 25px / 2);
  left: calc(50% - 25px / 2);
`

const DropPage = () => {
  const { id } = useParams()
  const { user, onAuthSuccess, setShowAuth, jsConfetti } =
    useContext(RootContext)
  const location = useLocation()
  const history = useHistory()
  const getDropQuery = useGetDrop(id)
  const videoRef = useRef(null)
  const saveDropResponse = useSaveDropResponse({
    dropID: id,
    linkID: location.state?.link?.id,
  })
  const { mutate: saveDropViewMutate } = useSaveDropView({
    dropID: id,
    linkID: location.state?.link?.id,
  })

  const drop = getDropQuery?.data?.drop

  // called only once
  useEffect(() => {
    if (drop) {
      saveDropViewMutate()
      window.analytics.track('Drop Viewed', makeDropProperties(drop))
    }
  }, [saveDropViewMutate, drop])

  useEffect(() => {
    if (!drop && !getDropQuery?.isLoading) history.push('/404')
  }, [drop, getDropQuery?.isLoading, history])

  const handleLocationClick = () => {
    window.open(`https://maps.google.com/?q=${drop?.address}`, '_blank')
  }

  const handleResponseClick = async e => {
    const resp = e.currentTarget.value
    const emoji = resp === 'LIKE' ? '💜' : '💩'
    const showConfetti = () =>
      jsConfetti.addConfetti({
        emojis: [emoji],
        emojiSize: 120,
      })
    try {
      window.analytics.track('Drop Response Clicked', {
        ...makeDropProperties(drop),
        response: resp,
      })

      if (!user?.accessToken)
        onAuthSuccess.current = () => {
          // todo: fix api calls and this problem should go away
          // todo: add analytics to all the new flows
          saveDropResponse.mutate(resp)
          showConfetti()
        }
      else {
        saveDropResponse.mutate(resp)
        showConfetti()
      }
      setShowAuth(true)
    } catch (error) {
      // tbd: catch error
    }
  }

  const renderDescriptions = () => {
    const bullets = drop.description
      .trim()
      .split('\n')
      .map(c => c.replace(/^- /g, ''))

    return (
      <Description>
        {bullets.map((curr, index) => (
          <BulletContainer
            style={{ marginTop: index !== 0 ? 15 : 0 }}
            key={`bullet-${index}`} // eslint-disable-line react/no-array-index-key
          >
            <p style={{ paddingRight: 10 }}>•</p>
            <p>{curr}</p>
          </BulletContainer>
        ))}
      </Description>
    )
  }

  if (!drop)
    return (
      <Spinner
        src={spinner}
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 0.5, ease: 'linear' }}
      />
    )

  return (
    <Container>
      <SEO title={drop.title} />
      <MaxMobileSizeContainer>
        <StyledVideo
          ref={videoRef}
          poster={drop.thumbnail}
          src={drop.video}
          muted
          autoPlay
          loop
          playsInline
          preload="metadata"
        />
        <GradientOverlay />
        <Content>
          {location?.state?.link?.createdBy?.name && (
            <Title>Let {location.state.link.createdBy.name} Know...</Title>
          )}
          <BottomContainer>
            <Descriptions
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <h2>{drop.title}</h2>
              <Location onClick={handleLocationClick}>
                <Pin src={pin} />
                <span>
                  {drop.location || (drop.address || '').split(',')[0]}
                </span>
              </Location>
              {renderDescriptions()}
            </Descriptions>
            <Response>
              <Button
                response={drop.response}
                value="LIKE"
                onClick={handleResponseClick}
              >
                💜 Let's Go!
              </Button>
              <Button
                response={drop.response}
                value="DISLIKE"
                onClick={handleResponseClick}
              >
                💩 Looks Lame
              </Button>
            </Response>
          </BottomContainer>
        </Content>
      </MaxMobileSizeContainer>
    </Container>
  )
}

export default DropPage
