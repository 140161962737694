/**
 * @file Input Component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useController } from 'react-hook-form'
import { AnimatePresence, motion } from 'framer-motion'
import spinner from 'images/spinner.png'

const Container = styled.div`
  background-color: #b57aff;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 15px;
  color: white;
  border-radius: 8px 8px ${({ error }) => (error ? '0 0' : '8px 8px')};
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  position: relative;
  z-index: 1;

  :before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    transition: 0.1s ease;
    border-style: solid;
    border-color: ${({ error, theme }) => (error ? theme.Error : '#ffffff00')};
    border-width: 4px;
    border-radius: inherit;
  }

  :focus-within {
    :before {
      border-color: ${({ error, theme }) => (error ? theme.Error : '#ffffff')};
    }
  }

  > input {
    color: white;
    background-color: inherit;
    outline: none;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
    width: 100%;

    ::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
`

const Spinner = styled(motion.div)`
  height: 25px;
  width: 25px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 15px;
`

const Error = styled(motion.h5)`
  background-color: ${({ theme }) => theme.Error};
  color: white;
  border-radius: 0 0 8px 8px;
  text-align: left;
  position: relative;
`

const ErrorMessage = styled(motion.span)`
  position: absolute;
  left: 0;
  right: 0;
  padding: 5px 15px 8px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Input = ({
  control,
  name,
  defaultValue,
  required,
  validate,
  title,
  style,
  loading,
  onChange,
  ...inputProps
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules: { required, validate },
  })

  const handleOnChange = e => {
    const val = inputProps.type === 'file' ? e.target.files[0] : e.target.value
    field.onChange(val)
    onChange(val)
  }

  const transform = {
    input: v => {
      if (inputProps.type === 'file') return undefined
      return v
    },
  }

  return (
    <>
      <Container
        style={style}
        error={fieldState.error?.message || fieldState?.invalid}
      >
        {title && <p>{title}</p>}
        <input
          {...inputProps}
          {...field}
          onChange={handleOnChange}
          value={transform.input(field.value)}
        />
        {loading && (
          <Spinner
            src={spinner}
            animate={{ rotate: 360 }}
            transition={{ repeat: Infinity, duration: 0.5, ease: 'linear' }}
          />
        )}
      </Container>
      <AnimatePresence>
        {(fieldState.error?.message || fieldState?.invalid) && (
          <Error
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 30, opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ ease: 'easeOut', duration: 0.2 }}
          >
            <ErrorMessage
              initial={{ y: -60 }}
              animate={{ y: 0 }}
              exit={{ y: -60 }}
            >
              {fieldState.error?.message || 'Required'}
            </ErrorMessage>
          </Error>
        )}
      </AnimatePresence>
    </>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
}

Input.defaultProps = {
  defaultValue: '',
  required: false,
  validate: null,
  title: '',
  loading: false,
  style: {},
  onChange: () => {},
}

export default Input
